import * as React from "react"
import { Formik } from "formik"
const axios = require("axios").default

import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const IndexPage = () => {
  const [msg, setMsg] = React.useState("")
  const [isSent, setIsSent] = React.useState(false)

  return (
    <Layout
      className="text-center"
      style={{ backgroundColor: "#d8d3c0", overflowY: "scroll" }}
    >
      <Seo title="Contact Us" />
      <div
        className="max-w-xl mx-auto text-center px-4 pb-24 tracking-wider pt-64 md:pt-24"
        style={{ color: "#958e74" }}
      >
        <h1 className="text-3xl md:text-5xl font-semibold text-primary font-header tracking-widest">
          Contact Us
        </h1>
        <div className="py-8 text-base md:text-xl font-semibold text-primary font-body">
          <div className="text-sm text-primary/60">
            Developer’s Official Website
          </div>
          <a href="https://claydence99stillroad.sg">claydence99stillroad.sg</a>
          <div className="text-sm mt-8 text-primary/60">Or email</div>
          <a href="mailto:'claydence99stillroad@honghow.com.sg'">
            claydence99stillroad@honghow.com.sg
          </a>
          <div className="text-sm mt-8 text-primary/60">
            Claydence Sales Gallery is located at
          </div>
          <p>
            8 Wilkie Road #01-09/10
            <br />
            Wilkie Edge
            <br />
            Singapore 228095
          </p>
          <div className="text-sm mt-8 text-primary/60">
            And available for viewing by appointment
          </div>
          <p>Weekday 11am – 7pm, Weekend 10am to 7pm</p>
          <p className="text-sm mt-8 mb-4 text-primary/60">
            through appointed Property Agents
          </p>
          <div className="flex space-x-8">
            <div className="w-full md:w-1/3 pt-4 pb-8">
              <div className="h-24 mb-4">
                <StaticImage
                  objectFit="contain"
                  className="max-h-24 mb-4"
                  src="../images/logo-propnex.png"
                  alt="Propnex"
                  width={400}
                  height={200}
                  formats={["auto", "webp", "avif"]}
                />
              </div>
              <div className="text-sm">
                <p>Jen Tan</p>
                <p className="text-xs">(CEA R020846B)</p>
                <a href="tel:6590662926">+65 9066 2926</a>
                <div className="pt-4">
                  <p>Victor Ng</p>
                  <p className="text-xs">(CEA R025250Z)</p>
                  <a href="tel:6588287288">+65 8828 7288</a>
                </div>
                <div className="pt-4">
                  <p>Millie Chin</p>
                  <p className="text-xs">(CEA R011008Z)</p>
                  <a href="tel:6592703950">+65 9270 3950</a>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/3 pt-4 pb-8">
              <div className="h-24 mb-4 flex items-center">
                <StaticImage
                  objectFit="contain"
                  src="../images/logo-huttons.png"
                  alt="Huttons"
                  width={400}
                  height={200}
                  formats={["auto", "webp", "avif"]}
                />
              </div>
              <div className="text-sm">
                <p>Kevin Tan</p>
                <p className="text-xs">(CEA R008189F)</p>
                <a href="tel:6584444800">+65 8444 4800</a>
                <div className="pt-4">
                  <p>Benedict Lim</p>
                  <p className="text-xs">(CEA L3008899K)</p>
                  <a href="tel:6591088442">+65 9108 8442</a>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/3 pt-4 pb-8">
              <div className="h-24 mb-4 flex items-center">
                <StaticImage
                  objectFit="contain"
                  className="max-h-24 mb-4"
                  src="../images/logo-era.png"
                  alt="Propnex"
                  width={400}
                  height={490}
                  formats={["auto", "webp", "avif"]}
                />
              </div>
              <div className="text-sm">
                <p>Darryl Ho</p>
                <p className="text-xs">(CEA R029029J)</p>
                <a href="tel:6597691212">+65 9769 1212</a>
                <div className="pt-4">
                  <p>Myronz Ho</p>
                  <p className="text-xs">(CEA R007442C)</p>
                  <a href="tel:6583838554">+65 8383 8554</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
